import React from 'react'
import { ReactTyped } from 'react-typed'


const Hero = () => {
    return (
        <div className='text-white'>
            <div className='max-w-[800px] mt-[-96px] w-full h-screen mx-auto text-center flex flex-col justify-center'>
                <p className='font-bold p-2 text-[#c069ff] uppercase'>Invest in your online presence</p>
                <h1 className='md:text-6xl sm:text-5xl text-3xl font-bold lg:p-5'>change your appearance</h1>
                <div className='flex justify-center items-center'>
                    <p className='md:text-5xl sm:text-4xl text-xl font-bold py-4'>you will achieve</p>
                    <ReactTyped 
                    className='md:text-5xl sm:text-4xl text-xl font-bold md:py-4 pl-2 text-[#c069ff]'
                    strings={['success', 'attention', 'expansion']}
                    typeSpeed={150}
                    backSpeed={120}
                    loop
                    />
                </div>
                <p className='md:text-2xl sm:text-xl text-xs font-bold text-gray-500'>Take your website to the next level so that your company <br />stands out on the internet.</p>
                <button className='bg-[#c069ff] w-[200px] rounded-md font-medium my-6 mx-auto py-3 text-black'>Start Today</button>
            </div>  
        </div>
    );
}

export default Hero;